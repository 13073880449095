<header
  *transloco="let t; read: 'layout'"
  class="header"
  [class.header_scrolled]="isScrolled"
  [class.header_inverted]="invert"
>
  <div class="header__container container">
    <a class="d-flex" [routerLink]="['/']">
      <svg-icon
        src="/assets/icons/pirexpo.svg"
        class="header__logo"
        [applyClass]="true"
      />
    </a>

    <nav class="header__nav">
      <div
        #menuTrigger="matMenuTrigger"
        class="header__nav-item"
        [class.header__nav-item_active]="menuTrigger.menuOpen"
        [matMenuTriggerFor]="themesMenu"
      >
        <span> {{ t('header.themes') | titlecase }} </span>

        <svg-icon
          src="/assets/icons/chevron-down.svg"
          class="header__nav-icon"
          [class.header__nav-icon_active]="menuTrigger.menuOpen"
          [applyClass]="true"
        />
      </div>

      <a
        class="header__nav-item"
        [routerLink]="[ROUTE_TOKENS.EXPOSITION.EXPOSITION]"
        >{{ t('header.exposition') | titlecase }}</a
      >

      <a
        class="header__nav-item"
        [routerLink]="[ROUTE_TOKENS.PROGRAM.PROGRAM]"
        >{{ t('header.events') | titlecase }}</a
      >

      <a
        class="header__nav-item"
        [routerLink]="[ROUTE_TOKENS.PARTNERS.PARTNERS]"
        >{{ t('header.partners') | titlecase }}</a
      >
    </nav>

    <div class="header__buttons">
      <a class="header__btn" [routerLink]="[ROUTE_TOKENS.SEARCH]">
        <svg-icon
          src="/assets/icons/search.svg"
          class="header__icon"
          [applyClass]="true"
        />
      </a>

      <svg-icon
        src="/assets/icons/cart.svg"
        class="header__icon header__btn cart-btn"
        [class.cart-filled]="isCartFilled$$()"
        [applyClass]="true"
        (click)="openCart()"
      />

      @if (appFeaturesConfig.expoIsActive) {
        <pirexpo-button
          class="header__btn header__btn_full"
          size="small"
          [routerLink]="[ROUTE_TOKENS.TICKET.GET_TICKET]"
          [text]="t('header.buyTicket') | capitalize"
        />
      } @else {
        <pirexpo-button
          class="header__btn header__btn_full"
          size="small"
          [routerLink]="[
            ROUTE_TOKENS.EXHIBITOR.EXHIBITOR +
              '/' +
              ROUTE_TOKENS.EXHIBITOR.PARTICIPATION,
          ]"
          [text]="t('header.becomeAnExhibitor') | capitalize"
        />
      }

      <button class="header__btn header__btn-menu" (click)="openMenu()">
        {{ t('header.menu') | titlecase }}
      </button>

      <svg-icon
        src="/assets/icons/menu.svg"
        class="header__icon header__btn header__btn-burger"
        [applyClass]="true"
        (click)="openMenu()"
      />
    </div>
  </div>
</header>

<mat-menu
  #themesMenu="matMenu"
  xPosition="before"
  yPosition="below"
  backdropClass="header-mat-menu-backdrop"
>
  <ng-template matMenuContent>
    <ng-container *transloco="let t">
      <a
        class="header-mat-menu-item"
        mat-menu-item
        disableRipple="true"
        [routerLink]="[
          ROUTE_TOKENS.THEME + '/' + ROUTE_PARAMS_TOKENS.THEME.RESTAURANT,
        ]"
        >{{ t('restaurant') | titlecase }}</a
      >

      <a
        class="header-mat-menu-item"
        mat-menu-item
        disableRipple="true"
        [routerLink]="[
          ROUTE_TOKENS.THEME + '/' + ROUTE_PARAMS_TOKENS.THEME.COFFEE,
        ]"
        >{{ t('coffee') | titlecase }}</a
      >

      <a
        class="header-mat-menu-item"
        mat-menu-item
        disableRipple="true"
        [routerLink]="[
          ROUTE_TOKENS.THEME + '/' + ROUTE_PARAMS_TOKENS.THEME.HOTEL,
        ]"
        >{{ t('hotel') | titlecase }}</a
      >
    </ng-container>
  </ng-template>
</mat-menu>
