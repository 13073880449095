import { Inject, Injectable, computed } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { PIREXPO_CONFIG, PirexpoConfig } from '@pirexpo/shared/app-config';
import { EventDetailSerializerV2, Person } from '@swagger/models';
import { EventService } from '@swagger/services/event.service';
import { PersonService } from '@swagger/services/person.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

interface SpeakerPopupState {
  error?: unknown;
  events?: EventDetailSerializerV2[];
  loading?: boolean;
  speaker?: Person;
}

@Injectable()
export class SpeakerPopupComponentStore extends ComponentStore<SpeakerPopupState> {
  readonly error$$ = this.selectSignal((state) => state.error);

  readonly events$$ = this.selectSignal((state) => state.events);

  readonly getSpeakerDetailed = this.effect(
    (speakerId$: Observable<Person['id']>) => {
      return speakerId$.pipe(
        tap(() => this.patchState({ loading: true })),
        switchMap((speakerId) =>
          this.speakerService.personRead(String(speakerId)).pipe(
            tapResponse({
              error: (error: unknown) =>
                this.patchState({ error, loading: false }),
              next: (speaker) => this.patchState({ loading: false, speaker }),
            }),
          ),
        ),
        switchMap((speaker) => {
          if (!speaker.event_speakers?.length) return EMPTY;

          const ids = speaker.event_speakers
            .map((event) => <number>event.id)
            .join(',');

          return this.eventService
            .eventListList({
              ids,
              theme: `${this.appConfig.RESTAURANT_ID},${this.appConfig.HOTEL_ID},${this.appConfig.COFFEE_ID}`,
            })
            .pipe(
              tap({
                error: (error: unknown) =>
                  this.patchState({ error, loading: false }),
                next: (events) => this.patchState({ events, loading: false }),
              }),
              catchError(() => EMPTY),
            );
        }),
      );
    },
  );

  readonly loading$$ = this.selectSignal((state) => state.loading);

  readonly speaker$$ = this.selectSignal((state) => state.speaker);

  readonly uniqueThemesId$$ = computed(() => {
    const themesIds = this.speaker$$()?.event_speakers?.map(
      (person) => person.theme,
    );

    return new Set(themesIds);
  });

  constructor(
    @Inject(PIREXPO_CONFIG) private readonly appConfig: PirexpoConfig,
    private speakerService: PersonService,
    private eventService: EventService,
  ) {
    super({});
  }
}
