<mat-dialog-content class="popup greeting-popup">
  <div class="popup__top-container">
    <pirexpo-text-button
      class="greeting-popup__back-button"
      mat-dialog-close
      text="Назад"
      buttonType="withIcon"
    >
      <svg-icon
        class="back-icon"
        src="/assets/icons/back.svg"
        [applyClass]="true"
      />
    </pirexpo-text-button>

    <p class="greeting-popup__date" fxHide.gt-xs>
      {{ greeting$$()?.date | date: 'd MMMM y' }}
    </p>
    <div
      class="greeting-popup__person"
      fxLayout="row"
      fxLayoutAlign="flex-start flex-start"
      fxLayoutAlign.xs="flex-start center"
    >
      <img
        class="greeting-popup__person-img"
        loading="lazy"
        [src]="greeting$$()?.photo"
        [alt]="greeting$$()?.title"
      />
      <div class="greeting-popup__person-info">
        <p class="greeting-popup__date" fxHide.xs>
          {{ greeting$$()?.date | date: 'd MMMM y' }}
        </p>
        <p class="greeting-popup__name">{{ greeting$$()?.title }}</p>
        <p class="greeting-popup__status">{{ greeting$$()?.status }}</p>
      </div>
    </div>
  </div>
  <div class="popup__middle-container">
    <div class="greeting-popup__description">
      <p
        *ngIf="greeting$$()?.description; else documentImage"
        class="greeting-popup__description-text"
        [innerHtml]="greeting$$()?.description"
      ></p>
      <ng-template #documentImage>
        <img
          class="greeting-popup__description-img"
          loading="lazy"
          [src]="greeting$$()?.document_image"
          [alt]="greeting$$()?.title! + greeting$$()?.status"
        />
      </ng-template>
    </div>
  </div>
</mat-dialog-content>
