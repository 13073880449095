<mat-dialog-content class="popup area-popup">
  <div class="popup__header">
    <img
      loading="lazy"
      class="popup__header-bg"
      alt="area-popup-bg"
      [src]="area$$()?.photo?.image"
    />

    <div
      class="popup__header-container popup__middle-container"
      fxLayout="column"
      fxLayoutAlign="flex-end flex-start"
      fxLayoutAlign.lt-md="space-between flex-start"
    >
      <pirexpo-text-button
        class="area-popup__back-button"
        mat-dialog-close
        text="Назад"
        buttonType="withIcon"
      >
        <svg-icon
          class="back-icon"
          src="/assets/icons/back.svg"
          [applyClass]="true"
        />
      </pirexpo-text-button>

      @if (area$$()?.events?.length) {
        <pirexpo-badge
          type="transparent"
          [text]="
            area$$()?.events?.length +
            ' ' +
            (area$$()?.events?.length
              | wordDeclension: ['мероприятие', 'мероприятия', 'мероприятий'])
          "
        />
      }
    </div>
  </div>

  <div class="popup__container">
    <p
      class="popup__text area-popup__text popup__middle-container"
      [innerHtml]="area$$()?.description"
    ></p>

    @for (eventsByTime of events$$() | keyvalue; track $index) {
      <div class="section-title section-popup-title area-popup__slider">
        {{ eventsByTime.key }}
      </div>

      @for (
        events of $any(eventsByTime).value;
        let index = $index;
        track events[0]
      ) {
        @if (events.length) {
          @defer (on viewport) {
            <pirexpo-events-slider
              class="area-popup__slider-container area-popup__slider area-popup__events-slider"
              eventForPopup
              withIcon
              [events]="events"
              [title]="getTimeInterval(index)"
              [sliderName]="getSliderName($any(eventsByTime).key, index)"
            />
          } @placeholder {
            <div></div>
          }
        }
      }
    }

    @if (partners$$().generalPartners.length || partners$$().partners.length) {
      @if (partners$$().generalPartners.length) {
        <div class="area-popup__partners popup__bottom-container">
          <h3 class="area-popup__title">Генеральные партнеры</h3>

          <div class="area-popup__partners-grid">
            @for (partner of partners$$().generalPartners; track partner.id) {
              <pirexpo-card-partner
                class="card area-popup__partner"
                partnerForPopup
                [partner]="partner"
                (openPopup)="openPartnerPopup($event)"
              />
            }
          </div>
        </div>
      }

      @if (partners$$().partners.length) {
        <div class="area-popup__partners popup__bottom-container">
          <h3 class="area-popup__title">Ключевые партнеры</h3>

          <div class="area-popup__partners-grid">
            @for (partner of partners$$().partners; track partner.id) {
              <pirexpo-card-partner
                class="card area-popup__partner"
                partnerForPopup
                [partner]="partner"
                (openPopup)="openPartnerPopup($event)"
              />
            }
          </div>
        </div>
      }
    }
  </div>
</mat-dialog-content>
