<mat-dialog-content class="popup hotel-popup">
  <div class="popup__top-container">
    <!-- <text-button mat-dialog-close class="popup__back-btn color-dark-gray1 mt28">
      <icon class="popup__back-icon" [iconName]="'back'"></icon>
      <span>Назад</span>
    </text-button> -->
  </div>
  <div class="popup__middle-container">
    <div
      class="hotel-popup__header"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-between flex-start"
      fxLayoutAlign.lt-md="space-between center"
      fxLayoutAlign.xs="flex-start center"
    >
      <div
        class="hotel-popup__img-container"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <img class="hotel-popup__img" [src]="value.logo" [alt]="value.name" />
      </div>
      <div>
        <p class="hotel-popup__name">{{ value.name }}</p>
        <div
          fxLayout="row"
          fxLayoutAlign="flex-start flex-start"
          class="hotel-popup__address"
        >
          <!-- <icon class="map-pin-icon stroke-red" [iconName]="'map_pin'"></icon> -->
          <p>{{ value.address }}</p>
        </div>
        <!-- <p class="hotel-popup__additional-info">До МВЦ «Крокус Экспо» можно добраться пешком за 2 минуты</p> -->
      </div>
    </div>
    <p class="hotel-popup__title">О гостинице</p>
    <p class="hotel-popup__text" [innerHtml]="value.description"></p>
    <p class="hotel-popup__sub-title">Размещение</p>
    <div class="hotel-popup__table-container">
      <table class="hotel-popup__price-table hotel-popup__table-head">
        <tr>
          <td class="price-cell">Категория номера</td>
          <td class="price-cell">Спец. цена / сутки</td>
        </tr>
      </table>
      <table class="hotel-popup__price-table">
        <tr *ngFor="let offer of value.offers">
          <td class="price-cell">{{ offer.category }}</td>
          <td class="price-cell">{{ offer.price_offer }}</td>
        </tr>
      </table>
    </div>
    <div
      class="hotel-popup__price-info"
      [innerHtml]="value.additional_information"
    ></div>
  </div>
  <div
    class="hotel-popup__gallery popup__bottom-container"
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-between flex-start"
  >
    <img
      *ngFor="let photo of value.photos"
      class="hotel-popup__hotel-img"
      [src]="photo.image"
      [alt]="photo.id"
    />
  </div>
</mat-dialog-content>
