/* eslint-disable no-console */
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({ providedIn: 'root' })
export class PersistStorageService {
  constructor(@Inject(WINDOW) private readonly windowRef: Window) {}

  async setPersistStorage(): Promise<void> {
    const isAlreadyPersist =
      await this.windowRef.navigator.storage?.persisted();

    if (isAlreadyPersist) {
      console.info('Storage has already been switched to persistent mode.');
      return;
    }

    const persistModeEnabled =
      await this.windowRef.navigator.storage?.persist();

    if (persistModeEnabled) {
      console.info(
        'Browser has successfully changed the storage mode to persistent.',
      );
      return;
    }

    console.info(
      'The browser encountered problems when trying to change the storage mode.',
    );
  }
}
