<mat-dialog-content class="social-networks-popup">
  <div class="social-networks-popup__container">
    <form
      #formGroupDirective="ngForm"
      [formGroup]="subscribeForm"
      (ngSubmit)="subscribe()"
    >
      <p class="social-networks-popup__title">
        Подпишитесь на наши соцсети и рассылки!
      </p>
      <p class="social-networks-popup__sub-title">
        Самые важные новости для бизнес-сообщества<br />
        и нужные полезные материалы!
      </p>
      <div
        class="social-networks-popup__networks-list social-networks-popup__networks-list--highlighted"
      >
        @for (
          socialNetworkDetail of socialNetworksList;
          track socialNetworkDetail.formControlName
        ) {
          @if (socialNetworkDetail.highlighted) {
            <pirexpo-social-box
              class="social-networks-popup__social-box"
              [socialNetworkDetail]="socialNetworkDetail"
              [formControlName]="socialNetworkDetail.formControlName"
            />
          }
        }
      </div>
      <div class="social-networks-popup__networks-list">
        @for (
          socialNetworkDetail of socialNetworksList;
          track socialNetworkDetail.formControlName
        ) {
          @if (!socialNetworkDetail.highlighted) {
            @if (socialNetworkDetail.formControlName) {
              <pirexpo-social-box
                class="social-networks-popup__social-box social-networks-popup__social-box-custom"
                [socialNetworkDetail]="socialNetworkDetail"
                [formControlName]="socialNetworkDetail.formControlName"
              />
            } @else {
              <pirexpo-social-box
                class="social-networks-popup__social-box social-networks-popup__social-box-custom"
                [socialNetworkDetail]="socialNetworkDetail"
                [hideCheckbox]="true"
              />
            }
          }
        }
      </div>
      <div class="social-networks-popup__subscribe-group">
        <div class="social-networks-popup__subscribe-text">
          Вы можете подписаться на несколько рассылок, выбрав их галочкой
        </div>
        <div class="social-networks-popup__form-group">
          <mat-form-field
            class="social-networks-popup__input email-subscribe-mat-form-field custom-floating-label custom-email-floating-label custom-email-floating-label--bg-white"
          >
            <mat-label>Email</mat-label>
            <input
              autocomplete="email"
              type="email"
              matInput
              formControlName="email"
            />
          </mat-form-field>

          <pirexpo-button
            class="social-networks-popup__submit-btn"
            size="middle"
            text="Подписаться"
            type="submit"
            fullWidth
            [loading]="loading$$()"
          />
        </div>
        @if (formSubmitted) {
          <p
            class="social-networks-popup__subscribe-text social-networks-popup__subscribe-text-success"
            [@fadeIn]
          >
            Форма успешно отправлена
          </p>
        }
      </div>
    </form>
  </div>
</mat-dialog-content>
