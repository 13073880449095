<mat-dialog-content class="popup participation-popup">
  <div class="popup__top-container">
    <pirexpo-text-button
      class="participation-popup__back-button"
      mat-dialog-close
      text="Назад"
      buttonType="withIcon"
    >
      <svg-icon
        class="back-icon"
        src="/assets/icons/back.svg"
        [applyClass]="true"
      />
    </pirexpo-text-button>
  </div>

  <div class="popup__middle-container" fxHide.xs>
    <div
      class="participation-popup__header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      @if (brand$$()?.themes?.length) {
        @for (theme of brand$$()?.themes; track $index) {
          <pirexpo-badge
            class="participation-popup__badge"
            [type]="theme + ''"
          />
        }
      }
    </div>
  </div>

  <div class="popup__bottom-container">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="flex-start flex-start"
      fxLayoutAlign.xs="flex-start flex-start"
    >
      <div
        class="participation-popup__img-container"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <img
          loading="lazy"
          class="participation-popup__img"
          [src]="brand$$()?.logo?.image ?? ''"
          [alt]="brand$$()?.name ?? ''"
        />
      </div>

      <div class="participation-popup__info">
        <pirexpo-badge
          fxHide.gt-xs
          class="participation-popup__badge"
          text="экспонент"
          type="exhibitor"
        />

        <p class="participation-popup__name">{{ brand$$()?.name }}</p>

        <div fxHide.xs>
          @if (brand$$()?.email) {
            <div
              class="participation-popup__mail participation-popup__info-item"
              fxLayout="row"
              fxLayoutAlign="flex-start center"
            >
              <svg-icon
                class="participation-popup__icon"
                src="/assets/icons/envelope.svg"
              />
              <a [href]="'mailto:' + brand$$()?.email"
                >{{ brand$$()?.email }}
              </a>
            </div>
          }

          @if (brand$$()?.phone) {
            <div
              class="participation-popup__phone participation-popup__info-item"
              fxLayout="row"
              fxLayoutAlign="flex-start center"
            >
              <svg-icon
                class="participation-popup__icon"
                src="/assets/icons/phone.svg"
              />
              <a [href]="'tel:' + brand$$()?.phone">{{ brand$$()?.phone }} </a>
            </div>
          }

          @if (brand$$()?.website) {
            <div
              class="participation-popup__link participation-popup__info-item"
              fxLayout="row"
              fxLayoutAlign="flex-start center"
            >
              <svg-icon
                class="participation-popup__icon"
                src="/assets/icons/external_link.svg"
              />

              <a
                class="color-blue-dark-grayish"
                rel="noopener"
                target="_blank"
                [href]="brand$$()?.website"
                >{{ brand$$()?.website }}</a
              >
            </div>
          }

          @if (classificator$$().length) {
            <div
              class="participation-popup__link participation-popup__info-item"
              fxLayout="row"
              fxLayoutAlign="flex-start flex-start"
            >
              <svg-icon
                class="participation-popup__icon"
                src="/assets/icons/suitcase.svg"
              />

              <div>
                @for (
                  classificator of classificator$$();
                  let last = $last;
                  track classificator.id
                ) {
                  <a
                    class="classificator-link"
                    [routerLink]="[
                      ROUTE_TOKENS.EXPOSITION.EXPOSITION +
                        '/' +
                        ROUTE_TOKENS.EXPOSITION.ALL_EXHIBITORS,
                    ]"
                    [queryParams]="queryParams(classificator.id)"
                  >
                    <span class="color-blue-dark-grayish">{{
                      classificator.name | lowercase
                    }}</span>
                  </a>
                  @if (!$last) {
                    <span>,&thinsp;</span>
                  }
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="participation-popup__location-block">
      <p class="participation-popup__location-title">Расположение</p>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div
          class="participation-popup__location"
          fxLayout="row"
          fxLayoutAlign="flex-start center"
        >
          <svg-icon
            class="participation-popup__location-icon"
            src="/assets/icons/compass.svg"
          />

          @if (booth$$()?.hall_number) {
            <p class="participation-popup__hall">
              {{ booth$$()?.hall_number }} зал
            </p>
          }

          @if (booth$$()?.booth_number) {
            <p class="participation-popup__booth-number">
              {{ booth$$()?.booth_number }}
            </p>
          }
        </div>
      </div>
    </div>

    <div fxHide.gt-xs>
      @if (brand$$()?.email) {
        <div
          class="participation-popup__mail participation-popup__info-item"
          fxLayout="row"
          fxLayoutAlign="flex-start center"
        >
          <svg-icon
            class="participation-popup__icon"
            src="/assets/icons/envelope.svg"
          />

          <a [href]="'mailto:' + brand$$()?.email">
            {{ brand$$()?.email }}
          </a>
        </div>
      }

      @if (brand$$()?.phone) {
        <div
          class="participation-popup__phone participation-popup__info-item"
          fxLayout="row"
          fxLayoutAlign="flex-start center"
        >
          <svg-icon
            class="participation-popup__icon stroke-blue-dark-grayish"
            src="/assets/icons/phone.svg"
          />

          <a [href]="'tel:' + brand$$()?.phone">{{ brand$$()?.phone }}</a>
        </div>
      }

      @if (brand$$()?.website) {
        <div
          class="participation-popup__link participation-popup__info-item"
          fxLayout="row"
          fxLayoutAlign="flex-start center"
        >
          <svg-icon
            class="participation-popup__icon"
            src="/assets/icons/external_link.svg"
          />

          <a
            class="color-blue-dark-grayish"
            rel="noopener"
            target="_blank"
            [href]="brand$$()?.website"
            >{{ brand$$()?.website }}</a
          >
        </div>
      }

      @if (classificator$$().length) {
        <div
          class="participation-popup__link participation-popup__info-item"
          fxLayout="row"
          fxLayoutAlign="flex-start flex-start"
        >
          <svg-icon
            class="participation-popup__icon"
            src="/assets/icons/suitcase.svg"
          />

          <div>
            @for (
              classificator of classificator$$();
              let last = $last;
              track classificator.id
            ) {
              <a
                [routerLink]="[
                  ROUTE_TOKENS.EXPOSITION.EXPOSITION +
                    '/' +
                    ROUTE_TOKENS.EXPOSITION.ALL_EXHIBITORS,
                ]"
                [queryParams]="queryParams(classificator.id)"
              >
                <span class="color-blue-dark-grayish">{{
                  classificator.name | lowercase
                }}</span></a
              >
              @if (!$last) {
                <span>,&thinsp;</span>
              }
            }
          </div>
        </div>
      }
    </div>

    <div
      class="participation-popup__description"
      [innerHtml]="brand$$()?.description"
    ></div>
  </div>
</mat-dialog-content>
