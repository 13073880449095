<mat-dialog-content class="popup event-popup">
  <div class="popup__top-container">
    <div>
      <pirexpo-text-button
        class="event-popup__back-button"
        mat-dialog-close
        text="Назад"
        buttonType="withIcon"
      >
        <svg-icon
          class="back-icon"
          src="/assets/icons/back.svg"
          [applyClass]="true"
        />
      </pirexpo-text-button>
    </div>
    <div
      class="event-popup__header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="flex-start center">
        @if (event$$()?.theme?.id) {
          <pirexpo-badge
            class="event-popup__badge"
            [type]="event$$()?.theme?.id + ''"
          />
        }

        <div class="event-popup__event-type">{{ event$$()?.type?.name }}</div>
      </div>
    </div>
    <h2 class="event-popup__title">{{ event$$()?.name }}</h2>
  </div>
  <div fxLayout="column">
    <div class="popup__middle-container" fxFlexOrder.xs="2">
      <div class="event-popup__info">
        <div
          class="event-popup__date event-popup__info-item"
          fxLayout="row"
          fxLayoutAlign="flex-start center"
        >
          <svg-icon
            class="event-popup__icon"
            src="/assets/icons/calendar.svg"
          />
          <p class="event-popup__day">
            {{ event$$()?.time_start | date: 'd MMMM' }}
          </p>
          <p class="event-popup__time">
            {{ event$$()?.time_start | date: 'H:mm' }} -
            {{ event$$()?.time_finish | date: 'H:mm' }}
          </p>
        </div>
        @if (event$$()?.place) {
          <div
            class="event-popup__place event-popup__info-item"
            fxLayout="row"
            fxLayoutAlign="flex-start center"
          >
            <svg-icon
              class="event-popup__icon"
              src="/assets/icons/map-pin.svg"
            />
            <p>{{ event$$()?.place?.name }}</p>
          </div>
        }
        @if (eventThematics$$()?.length) {
          <div
            class="event-popup__themes event-popup__info-item"
            fxLayout="row"
            fxLayoutAlign="flex-start center"
          >
            <svg-icon
              class="event-popup__icon"
              src="/assets/icons/message.svg"
            />

            <div>
              @for (
                thematic of eventThematics$$();
                let last = $last;
                track thematic.id
              ) {
                <a
                  class="thematic-link"
                  [routerLink]="[
                    ROUTE_TOKENS.PROGRAM.PROGRAM +
                      '/' +
                      ROUTE_TOKENS.PROGRAM.SCHEDULE,
                  ]"
                  [queryParams]="queryParams(thematic.id)"
                >
                  <span>{{ thematic.name | lowercase }}</span>
                </a>
                @if (!$last) {
                  <span>,&thinsp;</span>
                }
              }
            </div>
          </div>
        }
      </div>
    </div>
    @if (speakers$$()?.length) {
      @for (speakersObj of speakersByType$$()?.entries(); track speakersObj) {
        <div
          class="event-popup__speakers-slider swiper-container"
          fxFlexOrder.xs="1"
        >
          <pirexpo-speakers-slider
            speakerForEventPage
            [title]="
              speakersObj?.[0] === 'default'
                ? ''
                : ((speakersObj?.[1]?.length! === 1
                    ? speakersObj?.[1]?.[0]?.type?.name
                    : speakersObj?.[1]?.[0]?.type?.name_plural) ?? '')
            "
            [speakers]="speakersObj[1] | mapSpeakers"
          />
        </div>
      }
    }
  </div>
  <div class="popup__bottom-container event-popup__bottom-container">
    <div
      class="event-popup__price-container"
      fxLayout="row"
      fxLayoutAlign="space-between flex-start"
      [class.align-center]="preRegistration$$() || freeEntrance$$()"
    >
      <div fxLayout="column" class="event-popup__price-row">
        @if (paidEvent$$()) {
          @if (
            !soldOut$$() &&
            !event$$()?.registration_closed &&
            !event$$()?.cancelled
          ) {
            <!-- <div
              class="event-popup__price-row-wrapper"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div class="event-popup__text">
                При покупке онлайн до&nbsp;<span
                  class="color-dark-gray semi-bold"
                  >20 октября 2024</span
                >
              </div>
              <div
                class="event-popup__price"
                fxLayout="row"
                fxLayoutAlign="flex-start center"
              >
                <p class="event-popup__price-num color-red">
                  {{ event$$()?.ticket_type?.price }}
                </p>
                <span class="color-red">&nbsp;&#8381;</span>
              </div>
            </div> -->
            <!-- @if (event$$()?.ticket_type?.price_offline) { -->
            <div
              class="event-popup__price-row-wrapper"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div class="event-popup__text">
                При покупке на&nbsp;<span class="color-dark-gray semi-bold"
                  >выставке</span
                >
              </div>
              <div
                class="event-popup__price"
                fxLayout="row"
                fxLayoutAlign="flex-start center"
              >
                <p class="event-popup__price-num">
                  {{
                    event$$()?.ticket_type?.price_offline ||
                      event$$()?.ticket_type?.price
                  }}
                </p>
                <span>&nbsp;&#8381;</span>
              </div>
            </div>
            <!-- } -->
          } @else {
            <p class="event-popup__sold-out-text">
              @if (event$$()?.cancelled) {
                Отменено
              } @else if (event$$()?.registration_closed) {
                Мероприятие закончилось
              } @else {
                Мест больше нет
              }
            </p>
          }
        }

        @if (preRegistration$$()) {
          <div
            class="event-popup__price-row-wrapper"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            [class.no-max-width]="
              soldOut$$() ||
              event$$()?.registration_closed ||
              event$$()?.cancelled
            "
          >
            @if (
              !soldOut$$() &&
              !event$$()?.registration_closed &&
              !event$$()?.cancelled
            ) {
              <div class="event-popup__text">
                Бесплатно по предварительной регистрации
              </div>
            } @else {
              <p
                class="event-popup__sold-out-text"
                [class.color-red]="!event$$()?.registration_closed"
              >
                @if (event$$()?.cancelled) {
                  Отменено
                } @else if (event$$()?.registration_closed) {
                  Мероприятие закончилось
                } @else {
                  Мест больше нет
                }
              </p>
            }
          </div>
        }

        @if (freeEntrance$$()) {
          <div
            class="event-popup__price-row-wrapper"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            [class.no-max-width]="
              soldOut$$() ||
              event$$()?.registration_closed ||
              event$$()?.cancelled
            "
          >
            @if (
              !soldOut$$() &&
              !event$$()?.registration_closed &&
              !event$$()?.cancelled
            ) {
              <div class="event-popup__text">Свободное посещение</div>
            } @else {
              <p
                class="event-popup__sold-out-text"
                [class.color-red]="!event$$()?.registration_closed"
              >
                @if (event$$()?.cancelled) {
                  Отменено
                } @else if (event$$()?.registration_closed) {
                  Мероприятие закончилось
                } @else {
                  Мест больше нет
                }
              </p>
            }
          </div>
        }
      </div>
      @if (
        (paidEvent$$() || preRegistration$$()) &&
        event$$()?.registration &&
        !soldOut$$() &&
        !event$$()?.registration_closed &&
        !event$$()?.cancelled
      ) {
        <pirexpo-button
          class="event-popup__buy-btn"
          size="small"
          [text]="!showMessage ? 'В корзину' : '+1'"
          [loading]="!!addToCartLoading$$()"
          (click.stop)="addToCart()"
        />
      }
    </div>
    <div
      class="event-popup__description"
      [innerHtml]="event$$()?.description"
    ></div>
  </div>
</mat-dialog-content>
