<mat-dialog-content class="popup compilation-popup">
  <div class="popup__header">
    <img
      loading="lazy"
      class="popup__header-bg"
      alt="compilation-popup-bg"
      [src]="compilation$$()?.photo?.image"
    />

    <div
      class="popup__header-container popup__middle-container"
      fxLayout="column"
      fxLayoutAlign="flex-end flex-start"
      fxLayoutAlign.lt-md="space-between flex-start"
    >
      <pirexpo-text-button
        class="compilation-popup__back-button"
        mat-dialog-close
        text="Назад"
        buttonType="withIcon"
      >
        <svg-icon
          class="back-icon"
          src="/assets/icons/back.svg"
          [applyClass]="true"
        />
      </pirexpo-text-button>

      <div>
        @if (compilation$$()?.events?.length) {
          <pirexpo-badge
            class="card-compilation__badge"
            type="transparent"
            [text]="
              compilation$$()?.events?.length +
              ' ' +
              (compilation$$()?.events?.length
                | wordDeclension: ['мероприятие', 'мероприятия', 'мероприятий'])
            "
          />

          <h2 class="popup__header-title">{{ compilation$$()?.name }}</h2>
        }
      </div>
    </div>
  </div>

  <div class="popup__container">
    <p class="popup__text compilation-popup__text popup__middle-container">
      {{ compilation$$()?.description }}
    </p>

    @for (eventsByTime of events$$() | keyvalue; track $index) {
      <div class="section-title section-popup-title compilation-popup__slider">
        {{ eventsByTime.key }}
      </div>

      @for (
        events of $any(eventsByTime).value;
        let index = $index;
        track events[0]
      ) {
        @if (events.length) {
          @defer (on viewport) {
            <pirexpo-events-slider
              class="compilation-popup__slider-container compilation-popup__slider compilation-popup__events-slider"
              eventForPopup
              withIcon
              [events]="events"
              [title]="getTimeInterval(index)"
              [sliderName]="getSliderName($any(eventsByTime).key, index)"
            />
          } @placeholder {
            <div></div>
          }
        }
      }
    }
  </div>
</mat-dialog-content>
