import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { SanitizePipe } from '@pirexpo/shared/libs';
import { CircleButtonComponent } from '@pirexpo/ui/buttons/circle-button';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SanitizePipe,
    MatDialogModule,
    CircleButtonComponent,
    SvgIconComponent,
  ],
  selector: 'pirexpo-video-popup',
  standalone: true,
  styleUrls: ['./video-popup.component.scss'],
  templateUrl: './video-popup.component.html',
})
export class VideoPopupComponent implements OnInit {
  readonly videoUrl$$ = signal<string>('');

  constructor(
    private dialogRef: MatDialogRef<VideoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { [key: string]: string },
  ) {}

  private getSocialUrl(socialType: string): string {
    switch (socialType) {
      case 'youtube':
        return 'https://www.youtube.com/embed/';
      case 'vk':
        return 'https://vk.com/video_ext.php?';
      default:
        return '';
    }
  }

  private getVideoId(data: { [key: string]: string }): string {
    switch (data['socialType']) {
      case 'youtube':
        return data['videoId'];
      case 'vk':
        return `oid=-${this.data['oid']}&id=${this.data['videoId']}`;
      default:
        return data['videoId'];
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const url = `${this.getSocialUrl(this.data['socialType'])}${this.getVideoId(this.data)}`;
    this.videoUrl$$.set(url);
  }
}
