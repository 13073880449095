import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { Hotel } from '@swagger/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, MatDialogModule, FlexLayoutModule],
  selector: 'pirexpo-hotel-popup',
  standalone: true,
  styleUrls: ['./hotel-popup.component.scss'],
  templateUrl: './hotel-popup.component.html',
})
export class HotelPopupComponent {
  @Input() value!: Hotel;
}
