import {
  AREA_POPUP_TOKEN,
  COMPILATION_POPUP_TOKEN,
  EVENT_POPUP_TOKEN,
  EXHIBITOR_POPUP_TOKEN,
  GREETING_POPUP_TOKEN,
  HOTEL_POPUP_TOKEN,
  NEWS_POPUP_TOKEN,
  ORDER_RENEW_POPUP_TOKEN,
  ORDER_TIMER_POPUP_TOKEN,
  PARTNER_PAGE_POPUP_TOKEN,
  PARTNER_POPUP_TOKEN,
  POPUP_SHELL_TOKEN,
  PRODUCT_POPUP_TOKEN,
  RELEASE_POPUP_TOKEN,
  RESTORE_TICKET_POPUP_TOKEN,
  SOCIAL_NETWORKS_TOKEN,
  SPEAKER_POPUP_TOKEN,
  // STAND_EVENT_POPUP_TOKEN,
  VIDEO_POPUP_TOKEN,
} from '@pirexpo/entities/popup';

import { AreaPopupComponent } from '../area-popup';
import { BestPricePopupComponent } from '../best-price-popup';
import { CompilationPopupComponent } from '../compilation-popup';
import { EventPopupComponent } from '../event-popup';
import { ExhibitorPopupComponent } from '../exhibitor-popup';
import { GreetingPopupComponent } from '../greeting-popup';
import { HotelPopupComponent } from '../hotel-popup';
import { NewsPopupComponent } from '../news-popup';
import { OrderRenewPopupComponent } from '../order-renew-popup';
import { OrderTimerPopupComponent } from '../order-timer-popup';
import { PartnerPagePopupComponent } from '../partner-page-popup';
import { PartnerPopupComponent } from '../partner-popup';
import { PopupShellComponent } from '../popup-shell';
import { ReleasePopupComponent } from '../release-popup';
import { RestoreTicketPopupComponent } from '../restore-ticket-popup';
import { SocialNetworksPopupComponent } from '../social-networks-popup';
import { SpeakerPopupComponent } from '../speaker-popup';
// import { StandEventPopupComponent } from '../stand-event-popup';
import { VideoPopupComponent } from '../video-popup';

export const POPUP_TOKENS = [
  { provide: AREA_POPUP_TOKEN, useValue: AreaPopupComponent },
  { provide: PRODUCT_POPUP_TOKEN, useValue: BestPricePopupComponent },
  { provide: COMPILATION_POPUP_TOKEN, useValue: CompilationPopupComponent },
  { provide: EVENT_POPUP_TOKEN, useValue: EventPopupComponent },
  { provide: GREETING_POPUP_TOKEN, useValue: GreetingPopupComponent },
  { provide: HOTEL_POPUP_TOKEN, useValue: HotelPopupComponent },
  {
    provide: EXHIBITOR_POPUP_TOKEN,
    useValue: ExhibitorPopupComponent,
  },
  { provide: PARTNER_POPUP_TOKEN, useValue: PartnerPopupComponent },
  { provide: PARTNER_PAGE_POPUP_TOKEN, useValue: PartnerPagePopupComponent },
  { provide: NEWS_POPUP_TOKEN, useValue: NewsPopupComponent },
  { provide: ORDER_RENEW_POPUP_TOKEN, useValue: OrderRenewPopupComponent },
  { provide: ORDER_TIMER_POPUP_TOKEN, useValue: OrderTimerPopupComponent },
  { provide: POPUP_SHELL_TOKEN, useValue: PopupShellComponent },
  { provide: RELEASE_POPUP_TOKEN, useValue: ReleasePopupComponent },
  {
    provide: RESTORE_TICKET_POPUP_TOKEN,
    useValue: RestoreTicketPopupComponent,
  },
  { provide: SOCIAL_NETWORKS_TOKEN, useValue: SocialNetworksPopupComponent },
  { provide: SPEAKER_POPUP_TOKEN, useValue: SpeakerPopupComponent },
  // { provide: STAND_EVENT_POPUP_TOKEN, useValue: StandEventPopupComponent },
  { provide: VIDEO_POPUP_TOKEN, useValue: VideoPopupComponent },
];
