import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Greeting } from '@swagger/models';
import { GreetingService } from '@swagger/services/greeting.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface GreetingPopupState {
  error?: unknown;
  greeting?: Greeting;
  loading?: boolean;
}

@Injectable()
export class GreetingPopupComponentStore extends ComponentStore<GreetingPopupState> {
  readonly error$$ = this.selectSignal((state) => state.error);

  readonly getGreetingDetailed = this.effect(
    (greetingId$: Observable<Greeting['id']>) => {
      return greetingId$.pipe(
        tap(() => this.patchState({ loading: true })),
        switchMap((greetingId) =>
          this.greetingService.greetingRead({ id: String(greetingId) }).pipe(
            tapResponse({
              complete: () => this.patchState({ loading: false }),
              error: (error: unknown) => this.patchState({ error }),
              next: (greeting) =>
                this.patchState({ greeting: <Greeting>(<unknown>greeting) }),
            }),
          ),
        ),
      );
    },
  );

  readonly greeting$$ = this.selectSignal((state) => state.greeting);

  readonly loading$$ = this.selectSignal((state) => state.loading);

  constructor(private greetingService: GreetingService) {
    super({});
  }
}
