import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Release } from '@swagger/models';
import { ReleaseService } from '@swagger/services/release.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface ReleasePopupState {
  error?: unknown;
  loading?: boolean;
  release?: Release;
}

@Injectable()
export class ReleasePopupComponentStore extends ComponentStore<ReleasePopupState> {
  readonly error$$ = this.selectSignal((state) => state.error);

  readonly getReleaseDetailed = this.effect(
    (releaseId$: Observable<Release['id']>) => {
      return releaseId$.pipe(
        tap(() => this.patchState({ loading: true })),
        switchMap((releaseId) =>
          this.releaseService.releaseRead(String(releaseId)).pipe(
            tapResponse({
              error: (error: unknown) =>
                this.patchState({ error, loading: false }),
              next: (release) => this.patchState({ loading: false, release }),
            }),
          ),
        ),
      );
    },
  );

  readonly loading$$ = this.selectSignal((state) => state.loading);

  readonly release$$ = this.selectSignal((state) => state.release);

  constructor(private releaseService: ReleaseService) {
    super({});
  }
}
