<mat-dialog-content class="order-timer-popup order-timer">
  <div class="popup__middle-container">
    <pirexpo-text-button
      class="order-timer__back-button"
      mat-dialog-close
      text="Назад"
      buttonType="withIcon"
    >
      <svg-icon
        class="back-icon"
        src="/assets/icons/back.svg"
        [applyClass]="true"
      />
    </pirexpo-text-button>
  </div>
  <div class="order-timer__container">
    <p class="order-timer__title">Время на оплату истекло</p>
    <pirexpo-button
      class="order-timer__button"
      size="middle"
      text="Оплатить еще раз"
      [loading]="!!orderLoading$$()"
      (click)="renewOrder()"
    />
  </div>
</mat-dialog-content>
