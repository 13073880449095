<mat-dialog-content class="restore-popup restore-ticket-popup">
  <div class="popup__middle-container">
    <pirexpo-text-button
      class="restore-ticket-popup__back-button"
      mat-dialog-close
      text="Назад"
      buttonType="withIcon"
    >
      <svg-icon
        class="back-icon"
        src="/assets/icons/back.svg"
        [applyClass]="true"
      />
    </pirexpo-text-button>
  </div>
  <div class="restore-ticket-popup__container">
    @if (!ticketRestore$$()) {
      <ng-container>
        <!-- <ng-container *ngIf="!submitted; else restoreSuccess"> -->
        <p class="restore-ticket-popup__title">Восстановление билета</p>

        <div class="restore-ticket-popup__group">
          <mat-form-field class="restore-ticket-popup__field">
            <mat-label>Email</mat-label>

            <input
              type="email"
              name="email"
              autocomplete="email"
              matInput
              required
              [formControl]="emailControl"
            />
          </mat-form-field>

          <pirexpo-circle-button
            class="restore-ticket-popup__button"
            type="primary"
            [disabled]="!emailControl.valid || !!ticketRestoreLoading$$()"
            (click)="restoreTicket()"
          >
            <svg-icon
              class="restore-ticket-popup__icon"
              src="/assets/icons/chevron-right-nav.svg"
              [applyClass]="true"
            />
          </pirexpo-circle-button>
        </div>
      </ng-container>
    } @else {
      <p
        class="restore-ticket-popup__title restore-ticket-popup__secondary-title"
      >
        Готово!
      </p>
      <p class="restore-ticket-popup__text">
        На вашу почту
        <span class="fw-600">{{ emailControl.value }}</span> мы отправили письмо
        с инструкцией по восстановлению билета
      </p>
      <div class="restore-ticket-popup__buttons-group">
        <pirexpo-button
          class="restore-button"
          size="middle"
          text="Отправить повторно"
          [loading]="!!ticketRestoreLoading$$()"
          (click)="restoreTicket()"
        />
        <a
          class="restore-ticket-popup__support-link"
          href="mailto:support@pirexpo.com"
        >
          <span class="restore-ticket-popup__secondary-text underline"
            >Письмо&nbsp;не&nbsp;пришло</span
          >
          <div class="alert-icon-container">
            <svg-icon
              class="alert-icon"
              src="/assets/icons/alert-circle.svg"
              [applyClass]="true"
            />
          </div>
        </a>
      </div>
    }
  </div>
</mat-dialog-content>
