import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  ViewChild,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormGroupDirective,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SocialBoxComponent } from '@pirexpo/features/forms/social-box';
import { SOCIAL_NETWORKS } from '@pirexpo/shared/app-config';
import { ButtonComponent } from '@pirexpo/ui/buttons/button';
import { Subscribe } from '@swagger/models';
import { SubscriptionsService } from '@swagger/services/subscriptions.service';

@Component({
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.2s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    SocialBoxComponent,
    ButtonComponent,
  ],
  selector: 'pirexpo-social-networks-popup',
  standalone: true,
  styleUrls: ['./social-networks-popup.component.scss'],
  templateUrl: './social-networks-popup.component.html',
})
export class SocialNetworksPopupComponent {
  @ViewChild('formGroupDirective') formGroupDirective!: FormGroupDirective;

  formSubmitted = false;
  readonly loading$$ = signal<boolean>(false);
  readonly socialNetworksList = SOCIAL_NETWORKS;

  readonly subscribeForm = this.formBuilder.group({
    bestchefs: [false],
    cakeshow: [false],
    coffee: [false],
    email: ['', [Validators.email, Validators.required]],
    hotel: [false],
    pizza: [false],
    restaurant: [false],
  });

  @Input() value!: unknown;

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private subscriptionsService: SubscriptionsService,
    private destroyRef: DestroyRef,
  ) {}

  subscribe(): void {
    if (!this.subscribeForm.valid) return;

    this.loading$$.set(true);
    const form = <Subscribe>this.subscribeForm.value;

    for (const key in form) {
      if (
        Object.prototype.hasOwnProperty.call(form, key) &&
        !form[key as keyof Subscribe]
      ) {
        delete form[key as keyof Subscribe];
      }
    }

    this.subscriptionsService
      .subscriptionsSubscribeCreate(form)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        error: () => this.loading$$.set(false),
        // eslint-disable-next-line perfectionist/sort-objects
        complete: () => {
          this.loading$$.set(false);
          this.formSubmitted = true;
          this.subscribeForm.reset();
          this.formGroupDirective.resetForm();
        },
      });
  }
}
