<mat-dialog-content class="popup speaker-popup">
  <div class="popup__top-container">
    <div>
      <pirexpo-text-button
        class="speaker-popup__back-button"
        mat-dialog-close
        text="Назад"
        buttonType="withIcon"
      >
        <svg-icon
          class="back-icon"
          src="/assets/icons/back.svg"
          [applyClass]="true"
        />
      </pirexpo-text-button>
    </div>
    <div
      class="speaker-popup__header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="flex-start center">
        @for (themeId of uniqueThemesId$$(); track $index) {
          <pirexpo-badge class="speaker-popup__badge" [type]="themeId + ''" />
        }
      </div>
    </div>
    <div
      class="speaker-popup__speaker"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
    >
      <img
        class="speaker-popup__img"
        loading="lazy"
        [alt]="speaker$$()?.name"
        [src]="
          speaker$$()?.photo?.[1]?.image ||
          speaker$$()?.photo?.[0]?.image ||
          fallbackImage
        "
      />
      <div class="speaker-popup__speaker-info">
        <div class="speaker-popup__name">
          <p>{{ speaker$$()?.firstname }}</p>
          <p>{{ speaker$$()?.secondname }}</p>
        </div>
        @if (speaker$$()?.status) {
          <p class="speaker-popup__status">
            {{ speaker$$()?.status }}
          </p>
        }
      </div>
    </div>
  </div>
  @if (speaker$$()?.description) {
    <div class="popup__middle-container">
      <p class="speaker-popup__title">О спикере</p>
      <p
        class="speaker-popup__description"
        [innerHtml]="speaker$$()?.description"
      ></p>
    </div>
  }

  @if (speaker$$()?.event_speakers?.length) {
    <pirexpo-events-slider
      class="speaker-popup__slider"
      eventForPopup
      title="Мероприятия с участием спикера"
      [events]="events$$()!"
    />
  }
</mat-dialog-content>
