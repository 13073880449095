import { Pipe, PipeTransform } from '@angular/core';
import { EventPerson, EventSpeaker } from '@swagger/models';

@Pipe({
  name: 'mapSpeakers',
  standalone: true,
})
export class MapSpeakersPipe implements PipeTransform {
  transform(value: EventSpeaker[]): EventPerson[] {
    return value.map((v) => v.person);
  }
}
