import { NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CircleButtonComponent } from '@pirexpo/ui/buttons/circle-button';
import { SvgIconComponent } from 'angular-svg-icon';
import { DynamicModule } from 'ng-dynamic-component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgComponentOutlet,
    MatDialogModule,
    DynamicModule,
    SvgIconComponent,
    CircleButtonComponent,
  ],
  selector: 'pirexpo-popup-shell',
  standalone: true,
  styleUrls: ['./popup-shell.component.scss'],
  templateUrl: './popup-shell.component.html',
})
export class PopupShellComponent {
  readonly inputs = { value: null };

  constructor(
    public dialogRef: MatDialogRef<PopupShellComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.inputs.value = data.dynamicData;
  }

  close(): void {
    this.dialogRef.close();
  }
}
