import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Self,
  computed,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { ExhibitorsSliderComponent } from '@pirexpo/features/sliders/exhibitors-slider';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { BestPrice } from '@swagger/models';
import { SvgIconComponent } from 'angular-svg-icon';

import { BestPricePopupComponentStore } from './best-price.component.state';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    FlexLayoutModule,
    SvgIconComponent,
    TextButtonComponent,
    ExhibitorsSliderComponent,
  ],
  providers: [BestPricePopupComponentStore],
  selector: 'pirexpo-best-price-popup',
  standalone: true,
  styleUrls: ['./best-price-popup.component.scss'],
  templateUrl: './best-price-popup.component.html',
})
export class BestPricePopupComponent implements OnInit {
  readonly bestPrice$$ = this.componentStore.bestPrice$$;

  readonly booths$$ = computed(
    () => this.bestPrice$$()?.participation_bestprice,
  );

  @Input() value!: BestPrice['id'];

  constructor(@Self() private componentStore: BestPricePopupComponentStore) {}

  ngOnInit(): void {
    if (this.value) this.componentStore.getBestPriceDetailed(this.value);
  }
}
