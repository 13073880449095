<mat-dialog-content class="popup participation-popup">
  <div class="popup__top-container">
    <div>
      <pirexpo-text-button
        class="participation-popup__back-button"
        mat-dialog-close
        text="Назад"
        buttonType="withIcon"
      >
        <svg-icon
          class="back-icon"
          src="/assets/icons/back.svg"
          [applyClass]="true"
        />
      </pirexpo-text-button>
    </div>
  </div>

  <div class="popup__middle-container" fxHide.xs></div>

  <div class="popup__bottom-container">
    <div fxLayout="column" fxLayoutAlign="flex-start center">
      <p class="participation-popup__name">{{ partner$$()?.name }}</p>

      @if (partner$$()?.status) {
        <div
          class="participation-popup__info-item"
          fxLayout="row"
          fxLayoutAlign="flex-start center"
        >
          {{ partner$$()?.status }}
        </div>
      }

      @if (partner$$()?.website) {
        <div
          class="participation-popup__website"
          fxLayout="row"
          fxLayoutAlign="flex-start center"
        >
          <svg-icon
            class="participation-popup__icon"
            src="/assets/icons/external_link.svg"
          />

          <a rel="noopener" target="_blank" [href]="partner$$()?.website">{{
            partner$$()?.website_view
          }}</a>
        </div>
      }
      <div
        class="participation-popup__img-container"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <img
          loading="lazy"
          class="participation-popup__img"
          [src]="
            $any(partner$$()?.logo_alt)?.image || $any(partner$$()?.logo)?.image
          "
          [alt]="partner$$()?.name"
        />
      </div>
    </div>

    @if (partner$$()?.description) {
      <p class="title">О компании:</p>

      <div
        class="participation-popup__description"
        [innerHtml]="partner$$()?.description"
      ></div>
    }

    @if (partner$$()?.contact) {
      <p class="title">Контактное лицо:</p>

      <div
        class="participation-popup__description"
        [innerHtml]="partner$$()?.contact"
      ></div>
    }
  </div>
</mat-dialog-content>
