<mat-dialog-actions class="dialog-actions">
  <pirexpo-circle-button
    class="popup__close-btn"
    type="download"
    (click)="close()"
  >
    <svg-icon class="close-icon" src="/assets/icons/close.svg" />
  </pirexpo-circle-button>
</mat-dialog-actions>

<mat-dialog-content class="popup-shell">
  <div class="popup-shell__container">
    <ng-container
      *ngComponentOutlet="data.component; ndcDynamicInputs: inputs"
    />
  </div>
</mat-dialog-content>
