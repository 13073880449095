import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { News } from '@swagger/models';
import { NewsService } from '@swagger/services/news.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface NewsPopupState {
  error?: unknown;
  loading?: boolean;
  news?: News;
}

@Injectable()
export class NewsPopupComponentStore extends ComponentStore<NewsPopupState> {
  readonly error$$ = this.selectSignal((state) => state.error);

  readonly getNewsDetailed = this.effect((newsId$: Observable<News['id']>) => {
    return newsId$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap((newsId) =>
        this.newsService.newsRead(String(newsId)).pipe(
          tapResponse({
            error: (error: unknown) =>
              this.patchState({ error, loading: false }),
            next: (news) => this.patchState({ loading: false, news }),
          }),
        ),
      ),
    );
  });

  readonly loading$$ = this.selectSignal((state) => state.loading);

  readonly news$$ = this.selectSignal((state) => state.news);

  constructor(private newsService: NewsService) {
    super({});
  }
}
