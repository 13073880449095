import { DOCUMENT, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  Input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLinkWithHref } from '@angular/router';
import { WA_WINDOW } from '@ng-web-apis/common';
import { TranslocoDirective, provideTranslocoScope } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { cartFeature } from '@pirexpo/entities/cart';
import { MenuService } from '@pirexpo/shared/api';
import {
  PIREXPO_FEATURES_CONFIG,
  PirexpoFeaturesConfig,
  ROUTE_PARAMS_TOKENS,
  ROUTE_TOKENS,
} from '@pirexpo/shared/app-config';
import { CapitalizePipe } from '@pirexpo/shared/libs';
import { ButtonComponent } from '@pirexpo/ui/buttons/button';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLinkWithHref,
    TitleCasePipe,
    CapitalizePipe,
    SvgIconComponent,
    TranslocoDirective,
    MatMenuModule,
    ButtonComponent,
  ],
  providers: [provideTranslocoScope('layout')],
  selector: 'pirexpo-header',
  standalone: true,
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  readonly ROUTE_PARAMS_TOKENS = ROUTE_PARAMS_TOKENS;
  readonly ROUTE_TOKENS = ROUTE_TOKENS;
  @Input({ required: true }) invert!: boolean;

  readonly isCartFilled$$ = toSignal(
    this.store.select(cartFeature.selectCartLength),
  );

  isScrolled!: boolean;

  constructor(
    @Inject(PIREXPO_FEATURES_CONFIG)
    public readonly appFeaturesConfig: PirexpoFeaturesConfig,
    @Inject(WA_WINDOW) private readonly windowRef: Window,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    private menuService: MenuService,
    private store: Store,
  ) {}

  @HostListener('window:scroll')
  onWindowScroll(): void {
    const offset =
      this.windowRef.scrollY || this.documentRef.documentElement.scrollTop;

    this.isScrolled = offset > this.windowRef.innerHeight / 3;
  }

  openCart(): void {
    this.menuService.openMenu('cart');
  }

  openMenu(): void {
    this.menuService.openMenu('menu');
  }
}
