import { Inject, Injectable, computed } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { PIREXPO_CONFIG, PirexpoConfig } from '@pirexpo/shared/app-config';
import {
  TIME_INTERVALS,
  createSliderConfigs,
  filterEventsByTime,
  filterPlainArray,
  prepareEventForTemplate,
} from '@pirexpo/shared/helpers';
import { PreparedEvent, TodoAny } from '@pirexpo/shared/model';
import { Area, AreaDetailSerializerV2, Partner } from '@swagger/models';
import { AreaService } from '@swagger/services/area.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface AreaPopupState {
  area?: AreaDetailSerializerV2;
  error?: unknown;
  loading?: boolean;
}

@Injectable()
export class AreaPopupComponentStore extends ComponentStore<AreaPopupState> {
  readonly area$$ = this.selectSignal((state) => state.area);

  readonly error$$ = this.selectSignal((state) => state.error);

  readonly events$$ = computed(() => {
    const daysArray: string[] = [];
    let uniqueDays: string[] = [];
    const processedEvents: PreparedEvent[] = [];
    const eventsByDay: TodoAny = {};

    this.area$$()?.events?.map((event: TodoAny) => {
      const processedEvent = prepareEventForTemplate(event);
      daysArray.push(`${processedEvent.day}`);
      processedEvents.push(processedEvent);
    });

    uniqueDays = [...new Set(daysArray)].sort((a, b) => (a > b ? 1 : -1));

    uniqueDays.forEach((day) => {
      const filteredEventsByDay: TodoAny = {};
      filteredEventsByDay[day] = filterPlainArray(processedEvents, {
        day: [day],
      });
      eventsByDay[day] = [];

      TIME_INTERVALS.forEach((interval, index) => {
        eventsByDay[day][index] = [
          ...filterEventsByTime(
            filteredEventsByDay[day],
            interval.timeStart,
            interval.timeFinish,
          ),
        ];
      });
    });

    createSliderConfigs(eventsByDay, {});

    return eventsByDay;
  });

  readonly getAreaDetailed = this.effect((areaId$: Observable<Area['id']>) => {
    return areaId$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap((areaId) =>
        this.areaService.areaRead(String(areaId)).pipe(
          tapResponse({
            error: (error: unknown) =>
              this.patchState({ error, loading: false }),
            next: (area) => this.patchState({ area, loading: false }),
          }),
        ),
      ),
    );
  });

  readonly loading$$ = this.selectSignal((state) => state.loading);

  readonly partners$$ = computed(() => {
    const generalPartners: Partner[] = [];
    const partners: Partner[] = [];

    this.area$$()?.partners.forEach((partner) => {
      if (partner.type === this.appConfig.GENERAL_PARTNER_TYPE_ID) {
        generalPartners.push(partner);
      } else {
        partners.push(partner);
      }
    });

    return { generalPartners, partners };
  });

  constructor(
    @Inject(PIREXPO_CONFIG) private readonly appConfig: PirexpoConfig,
    private areaService: AreaService,
  ) {
    super({});
  }
}
