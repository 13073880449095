<mat-dialog-content class="popup news-popup">
  <div class="popup__header">
    <img
      loading="lazy"
      class="popup__header-bg"
      alt="news-popup-bg"
      [src]="release$$()?.image"
    />

    <div
      class="popup__header-container popup__middle-container"
      fxLayout="column"
      fxLayoutAlign="flex-end flex-start"
      fxLayoutAlign.lt-md="space-between flex-start"
    >
      <pirexpo-text-button
        class="news-popup__back-button"
        mat-dialog-close
        text="Назад"
        buttonType="withIcon"
      >
        <svg-icon
          class="back-icon"
          src="/assets/icons/back.svg"
          [applyClass]="true"
        />
      </pirexpo-text-button>

      @if (release$$()?.themes?.length) {
        <div class="relative" fxLayout="row" fxLayoutAlign="flex-start center">
          @for (themeId of release$$()?.themes; track themeId) {
            <pirexpo-badge class="popup__badge" [type]="themeId + ''" />
          }
        </div>
      }
    </div>
  </div>

  <div class="popup__container">
    <div class="popup__bottom-container">
      <div
        class="popup__text news-popup__text"
        [innerHtml]="release$$()?.description"
      ></div>
    </div>
  </div>
</mat-dialog-content>
