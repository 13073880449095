/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { VisitorToken } from '../models/visitor-token';
@Injectable({
  providedIn: 'root',
})
class GetVisitorTokenService extends __BaseService {
  static readonly getVisitorTokenListPath = '/get_visitor_token';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `GetVisitorTokenService.GetVisitorTokenListParams` containing the following parameters:
   *
   * - `ym_client_id`: Yandex Metrika client id
   *
   * - `shop_id`: shop_id
   *
   * - `ga_client_id`: Google Analytics client id
   */
  getVisitorTokenListResponse(params: GetVisitorTokenService.GetVisitorTokenListParams): __Observable<__StrictHttpResponse<VisitorToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ymClientId != null) __params = __params.set('ym_client_id', params.ymClientId.toString());
    if (params.shopId != null) __params = __params.set('shop_id', params.shopId.toString());
    if (params.gaClientId != null) __params = __params.set('ga_client_id', params.gaClientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/get_visitor_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitorToken>;
      })
    );
  }
  /**
   * @param params The `GetVisitorTokenService.GetVisitorTokenListParams` containing the following parameters:
   *
   * - `ym_client_id`: Yandex Metrika client id
   *
   * - `shop_id`: shop_id
   *
   * - `ga_client_id`: Google Analytics client id
   */
  getVisitorTokenList(params: GetVisitorTokenService.GetVisitorTokenListParams): __Observable<VisitorToken> {
    return this.getVisitorTokenListResponse(params).pipe(
      __map(_r => _r.body as VisitorToken)
    );
  }
}

module GetVisitorTokenService {

  /**
   * Parameters for getVisitorTokenList
   */
  export interface GetVisitorTokenListParams {

    /**
     * Yandex Metrika client id
     */
    ymClientId?: string;

    /**
     * shop_id
     */
    shopId?: number;

    /**
     * Google Analytics client id
     */
    gaClientId?: string;
  }
}

export { GetVisitorTokenService }
