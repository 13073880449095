<mat-dialog-content class="popup participation-popup">
  <div class="popup__top-container">
    <div>
      <pirexpo-text-button
        class="participation-popup__back-button"
        mat-dialog-close
        text="Назад"
        buttonType="withIcon"
      >
        <svg-icon
          class="back-icon"
          src="/assets/icons/back.svg"
          [applyClass]="true"
        />
      </pirexpo-text-button>
    </div>
  </div>
  <div class="popup__middle-container" fxHide.xs>
    <div
      class="participation-popup__header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      @for (theme of partner$$()?.themes; track $index) {
        <pirexpo-badge class="participation-popup__badge" [type]="theme" />
      }
    </div>
  </div>
  <div class="popup__bottom-container">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="flex-start center"
      fxLayoutAlign.xs="flex-start flex-start"
    >
      <div
        class="participation-popup__img-container"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <img
          loading="lazy"
          class="participation-popup__img"
          [src]="
            $any(partner$$()?.logo_alt)?.image || $any(partner$$()?.logo)?.image
          "
          [alt]="partner$$()?.name"
        />
      </div>
      <div class="participation-popup__info">
        <pirexpo-badge
          fxHide.gt-xs
          class="participation-popup__badge"
          text="экспонент"
          type="exhibitor"
        />
        <p class="participation-popup__name">{{ partner$$()?.name }}</p>
        <div fxHide.xs>
          @if (partner$$()?.website) {
            <div
              class="participation-popup__link participation-popup__info-item"
              fxLayout="row"
              fxLayoutAlign="flex-start center"
            >
              <svg-icon
                class="participation-popup__icon"
                src="/assets/icons/external_link.svg"
              />

              <a
                class="color-blue-dark-grayish"
                rel="noopener"
                target="_blank"
                [href]="partner$$()?.website"
                >{{ partner$$()?.website }}</a
              >
            </div>
          }
        </div>
      </div>
    </div>
    <div fxHide.gt-xs>
      @if (partner$$()?.website) {
        <div
          class="participation-popup__link participation-popup__info-item"
          fxLayout="row"
          fxLayoutAlign="flex-start center"
        >
          <svg-icon
            class="participation-popup__icon"
            src="/assets/icons/external_link.svg"
          />
          <a
            class="color-blue-dark-grayish"
            rel="noopener"
            target="_blank"
            [href]="partner$$()?.website"
            >{{ partner$$()?.website }}</a
          >
        </div>
      }
    </div>
    <div
      class="participation-popup__description"
      [innerHtml]="partner$$()?.description"
    ></div>
  </div>
</mat-dialog-content>
