import { isDevMode } from '@angular/core';
import { Route } from '@angular/router';
import { ROUTE_PARAMS_TOKENS, ROUTE_TOKENS } from '@pirexpo/shared/app-config';

export const appRoutes: Route[] = [
  {
    loadChildren: () =>
      import('@pirexpo/pages/about-exhibition').then(
        (m) => m.aboutExhibitionRoutes,
      ),
    path: ROUTE_TOKENS.ABOUT_EXHIBITION.ABOUT_EXHIBITION,
  },
  {
    loadComponent: () =>
      import('@pirexpo/pages/address').then((m) => m.AddressComponent),
    path: ROUTE_TOKENS.ADDRESS,
  },
  {
    loadComponent: () =>
      import('@pirexpo/pages/contacts').then((m) => m.ContactsComponent),
    path: ROUTE_TOKENS.CONTACTS,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/exhibitor').then((m) => m.exhibitorRoutes),
    path: ROUTE_TOKENS.EXHIBITOR.EXHIBITOR,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/exposition').then((m) => m.expositionRoutes),
    path: ROUTE_TOKENS.EXPOSITION.EXPOSITION,
  },
  {
    loadChildren: () => import('@pirexpo/pages/map').then((m) => m.mapRoutes),
    path: `${ROUTE_TOKENS.MAP}/:${ROUTE_PARAMS_TOKENS.HALL_NUMBER}`,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/partners').then((m) => m.partnersRoutes),
    path: ROUTE_TOKENS.PARTNERS.PARTNERS,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/press-center').then((m) => m.pressCenterRoutes),
    path: ROUTE_TOKENS.PRESS_CENTER.PRESS_CENTER,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/program').then((m) => m.programRoutes),
    path: ROUTE_TOKENS.PROGRAM.PROGRAM,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/themes').then((m) => m.themeRoutes),
    path: `${ROUTE_TOKENS.THEME}/:${ROUTE_PARAMS_TOKENS.THEME_NAME}`,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/visitor').then((m) => m.visitorRoutes),
    path: ROUTE_TOKENS.VISITOR,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/get-ticket').then((m) => m.getTicketRoutes),
    path: ROUTE_TOKENS.TICKET.GET_TICKET,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/tickets-details').then(
        (m) => m.ticketDetailsRoutes,
      ),
    path: `${ROUTE_TOKENS.TICKET.ORDER}/:${ROUTE_PARAMS_TOKENS.ORDER_ID}`,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/order').then((m) => m.orderRoutes),
    path: `${ROUTE_TOKENS.TICKET.PAYMENT}/:${ROUTE_PARAMS_TOKENS.ORDER_ID}`,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/tickets-complete').then(
        (m) => m.ticketsCompleteRoutes,
      ),
    path: `${ROUTE_TOKENS.TICKET.ORDER_COMPLETE}/:${ROUTE_PARAMS_TOKENS.ORDER_ID}`,
  },
  {
    loadChildren: () =>
      import('@pirexpo/pages/invoice-for-payment').then(
        (m) => m.invoiceForPaymentRoutes,
      ),
    path: `${ROUTE_TOKENS.TICKET.INVOICE_FOR_PAYMENT}/:${ROUTE_PARAMS_TOKENS.ORDER_ID}`,
  },
  {
    loadChildren: () => import('@pirexpo/pages/main').then((m) => m.mainRoutes),
    path: '',
  },
  {
    loadComponent: () =>
      import('@pirexpo/pages/search').then((m) => m.SearchComponent),
    path: ROUTE_TOKENS.SEARCH,
  },
  {
    canActivate: [() => isDevMode()],
    loadComponent: () =>
      import('@pirexpo/pages/dev').then((m) => m.DevComponent),
    path: ROUTE_TOKENS.DEV,
  },
  { path: '**', redirectTo: '' },
];
