import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pirexpo-text-button',
  standalone: true,
  styleUrls: ['./text-button.component.scss'],
  templateUrl: './text-button.component.html',
})
export class TextButtonComponent {
  @Input({ required: true }) text!: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ alias: 'buttonType', required: true }) type!:
    | 'underlined'
    | 'withIcon';
}
