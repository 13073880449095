import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
// import { REQUEST } from '@nguniversal/express-engine/tokens';
import { environment } from '@pirexpo/env';
import { Request } from 'express';
import { Observable } from 'rxjs';

const startsWithAny =
  (arr: string[] = []) =>
  (value = '') => {
    return arr.some((v) => value.toLowerCase().startsWith(v.toLowerCase()));
  };

const isAbsoluteURL = startsWithAny(['http', '//']);

export function UniversalRelativeInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  // const request: Request = inject(REQUEST);

  // if (request && !isAbsoluteURL(req.url)) {
  //   const directApiRequest = environment.production && req.url.includes('api');

  //   const protocolHost = directApiRequest
  //     ? environment.API_URL
  //     : `${request.protocol}://${request.get('host')}`;

  //   const url = `${protocolHost}${req.url.trim()}`;
  //   const serverRequest = req.clone({ url });

  //   return next(serverRequest);
  // }
  return next(req);
}
