<mat-dialog-actions>
  <pirexpo-circle-button
    class="popup__close-btn"
    type="download"
    (click)="close()"
  >
    <svg-icon class="close-icon" src="/assets/icons/close.svg" />
  </pirexpo-circle-button>
</mat-dialog-actions>
<mat-dialog-content class="video-popup-content video-popup">
  @if (data['socialType'] === 'youtube') {
    <iframe
      class="youtube-iframe"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      [src]="videoUrl$$() | safeSource: 'resourceUrl'"
    ></iframe>
  } @else if (data['socialType'] === 'vk') {
    <iframe
      class="vk-video-iframe"
      allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
      frameborder="0"
      allowfullscreen
      [src]="videoUrl$$() + '&hd=2&autoplay=1' | safeSource: 'resourceUrl'"
    ></iframe>
  }
</mat-dialog-content>
