import { DatePipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Self,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { Greeting } from '@swagger/models';
import { SvgIconComponent } from 'angular-svg-icon';

import { GreetingPopupComponentStore } from './greeting.component.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    DatePipe,
    MatDialogModule,
    FlexLayoutModule,
    SvgIconComponent,
    TextButtonComponent,
  ],
  providers: [GreetingPopupComponentStore],
  selector: 'pirexpo-greeting-popup',
  standalone: true,
  styleUrls: ['./greeting-popup.component.scss'],
  templateUrl: './greeting-popup.component.html',
})
export class GreetingPopupComponent implements OnInit {
  readonly greeting$$ = this.componentStore.greeting$$;

  @Input() value!: Greeting['id'];

  constructor(@Self() private componentStore: GreetingPopupComponentStore) {}

  ngOnInit(): void {
    if (this.value) this.componentStore.getGreetingDetailed(this.value);
  }
}
