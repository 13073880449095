import { DialogRef } from '@angular/cdk/dialog';
import {
  DatePipe,
  JsonPipe,
  KeyValuePipe,
  LowerCasePipe,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Self,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Params, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { CartActions, cartFeature } from '@pirexpo/entities/cart';
import { SpeakersSliderComponent } from '@pirexpo/features/sliders/speakers-slider';
import { MenuService } from '@pirexpo/shared/api';
import {
  ROUTE_QUERY_PARAMS_TOKENS,
  ROUTE_TOKENS,
} from '@pirexpo/shared/app-config';
import { ButtonComponent } from '@pirexpo/ui/buttons/button';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { BadgeComponent } from '@pirexpo/ui/common/badge';
import { EventDetailSerializerV2 } from '@swagger/models';
import { SvgIconComponent } from 'angular-svg-icon';
import { skip, tap } from 'rxjs/operators';

import { EventPopupComponentStore } from './event-popup.component.store';
import { MapSpeakersPipe } from './mapSpeakers.pipe';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    DatePipe,
    LowerCasePipe,
    KeyValuePipe,
    MapSpeakersPipe,
    JsonPipe,
    FlexLayoutModule,
    MatDialogModule,
    SvgIconComponent,
    TextButtonComponent,
    ButtonComponent,
    BadgeComponent,
    SpeakersSliderComponent,
  ],
  providers: [EventPopupComponentStore],
  selector: 'pirexpo-event-popup',
  standalone: true,
  styleUrls: ['./event-popup.component.scss'],
  templateUrl: './event-popup.component.html',
})
export class EventPopupComponent implements OnInit {
  private readonly ROUTE_QUERY_PARAMS_TOKENS = ROUTE_QUERY_PARAMS_TOKENS;

  private readonly cartLength$$ = toSignal(
    this.store.select(cartFeature.selectCartLength),
  );

  private readonly queryParams$$ = toSignal(
    this.route.queryParams.pipe(
      skip(1),
      tap(() => this.dialogRef.close()),
    ),
  );

  readonly ROUTE_TOKENS = ROUTE_TOKENS;

  readonly addToCartLoading$$ = toSignal(
    this.store.select(cartFeature.selectAddToCartLoading).pipe(
      skip(1),
      tap((loading) => {
        if (!loading) {
          this.showMessage = true;

          setTimeout(() => {
            this.showMessage = false;
            this.cdr.markForCheck();
          }, 2000);
        }
      }),
    ),
  );

  readonly event$$ = this.componentStore.event$$;
  readonly eventThematics$$ = this.componentStore.eventThematics$$;
  readonly freeEntrance$$ = this.componentStore.freeEntrance$$;
  readonly paidEvent$$ = this.componentStore.paidEvent$$;
  readonly preRegistration$$ = this.componentStore.preRegistration$$;
  showMessage = false;
  readonly soldOut$$ = this.componentStore.soldOut$$;
  readonly speakers$$ = this.componentStore.speakers$$;
  readonly speakersByType$$ = this.componentStore.speakersByType$$;

  @Input() value!: EventDetailSerializerV2['id'];

  constructor(
    private store: Store,
    private menuService: MenuService,
    private cdr: ChangeDetectorRef,
    @Self() private componentStore: EventPopupComponentStore,
    private route: ActivatedRoute,
    private dialogRef: DialogRef,
  ) {}

  addToCart(): void {
    if (!this.cartLength$$()) this.menuService.openMenu('cart');

    this.store.dispatch(
      CartActions.addCartItem({
        ticketTypeId: <number>this.event$$()?.ticket_type?.id,
      }),
    );
  }

  ngOnInit(): void {
    if (this.value) this.componentStore.getEventDetailed(this.value);
  }

  queryParams(id: number | undefined): Params {
    return {
      [this.ROUTE_QUERY_PARAMS_TOKENS.THEMATIC]: id,
    };
  }

  showCounter(): void {
    this.showMessage = true;
    setTimeout(() => {
      this.showMessage = false;
    }, 2000);
  }
}
