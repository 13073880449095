import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { BestPrice } from '@swagger/models';
import { BestpriceService } from '@swagger/services/bestprice.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface BestPricePopupState {
  bestPrice?: BestPrice;
  error?: unknown;
  loading?: boolean;
}

@Injectable()
export class BestPricePopupComponentStore extends ComponentStore<BestPricePopupState> {
  readonly bestPrice$$ = this.selectSignal((state) => state.bestPrice);

  readonly error$$ = this.selectSignal((state) => state.error);

  readonly getBestPriceDetailed = this.effect(
    (bestPriceId$: Observable<BestPrice['id']>) => {
      return bestPriceId$.pipe(
        tap(() => this.patchState({ loading: true })),
        switchMap((bestPriceId) =>
          this.bestPriceService.bestpriceRead(String(bestPriceId)).pipe(
            tapResponse({
              error: (error: unknown) =>
                this.patchState({ error, loading: false }),
              next: (bestPrice) =>
                this.patchState({ bestPrice, loading: false }),
            }),
          ),
        ),
      );
    },
  );

  readonly loading$$ = this.selectSignal((state) => state.loading);

  constructor(private bestPriceService: BestpriceService) {
    super({});
  }
}
