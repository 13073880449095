import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { OrderActions, orderFeature } from '@pirexpo/entities/order';
import { ButtonComponent } from '@pirexpo/ui/buttons/button';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    ButtonComponent,
    TextButtonComponent,
    SvgIconComponent,
  ],
  selector: 'pirexpo-order-timer-popup',
  standalone: true,
  styleUrls: ['./order-timer-popup.component.scss'],
  templateUrl: './order-timer-popup.component.html',
})
export class OrderTimerPopupComponent {
  readonly orderLoading$$ = toSignal(
    this.store.select(orderFeature.selectOrderLoading),
  );

  @Input() value!: string;

  constructor(private store: Store) {}

  renewOrder(): void {
    this.store.dispatch(OrderActions.renewOrder());
  }
}
