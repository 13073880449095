import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Self,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { BadgeComponent } from '@pirexpo/ui/common/badge';
import { Release } from '@swagger/models';
import { SvgIconComponent } from 'angular-svg-icon';

import { ReleasePopupComponentStore } from './release-popup.component.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    FlexLayoutModule,
    BadgeComponent,
    SvgIconComponent,
    TextButtonComponent,
  ],
  providers: [ReleasePopupComponentStore],
  selector: 'pirexpo-release-popup',
  standalone: true,
  styleUrls: ['./release-popup.component.scss'],
  templateUrl: './release-popup.component.html',
})
export class ReleasePopupComponent implements OnInit {
  readonly release$$ = this.componentStore.release$$;

  @Input() value!: Release['id'];

  constructor(@Self() private componentStore: ReleasePopupComponentStore) {}

  ngOnInit(): void {
    if (this.value) this.componentStore.getReleaseDetailed(this.value);
  }
}
