<mat-dialog-content class="popup best-price-popup">
  <div class="popup__top-container">
    <pirexpo-text-button
      class="best-price-popup__back-button"
      mat-dialog-close
      text="Назад"
      buttonType="withIcon"
    >
      <svg-icon
        class="back-icon"
        src="/assets/icons/back.svg"
        [applyClass]="true"
      />
    </pirexpo-text-button>
  </div>

  <div class="popup__middle-container">
    <div
      class="best-price-popup__header"
      fxLayout="row"
      fxLayout.xs="row wrap"
      fxLayoutAlign="space-between flex-start"
    >
      <div class="best-price-popup__header-col">
        <img
          loading="lazy"
          class="best-price-popup__img"
          [src]="bestPrice$$()?.image?.image ?? ''"
          [alt]="bestPrice$$()?.name ?? ''"
        />
      </div>

      <div class="best-price-popup__header-col">
        <div
          class="best-price-popup__header-top"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        ></div>

        <p class="best-price-popup__title">{{ bestPrice$$()?.name }}</p>

        <div class="best-price-popup__info">
          @if (bestPrice$$()?.participation_bestprice?.[0]?.email) {
            <div
              class="best-price-popup__info-item"
              fxLayout="row"
              fxLayoutAlign="flex-start center"
            >
              <svg-icon
                class="best-price-popup__icon stroke-blue-dark-grayish"
                src="/assets/icons/envelope.svg"
              />

              <p>{{ bestPrice$$()?.participation_bestprice?.[0]?.email }}</p>
            </div>
          }

          @if (bestPrice$$()?.participation_bestprice?.[0]?.phone) {
            <div
              class="best-price-popup__info-item"
              fxLayout="row"
              fxLayoutAlign="flex-start center"
            >
              <svg-icon
                class="best-price-popup__icon stroke-blue-dark-grayish"
                src="/assets/icons/phone.svg"
              />

              <p>{{ bestPrice$$()?.participation_bestprice?.[0]?.phone }}</p>
            </div>
          }

          @if (
            bestPrice$$()?.participation_bestprice?.[0]?.booths?.[0]
              ?.hall_number ||
            bestPrice$$()?.participation_bestprice?.[0]?.booths?.[0]
              ?.booth_number
          ) {
            <div
              class="best-price-popup__info-item"
              fxLayout="row"
              fxLayoutAlign="flex-start center"
            >
              <svg-icon
                class="best-price-popup__icon stroke-blue-dark-grayish"
                src="/assets/icons/map-pin.svg"
              />

              <p>
                <span
                  >{{
                    bestPrice$$()?.participation_bestprice?.[0]?.booths?.[0]
                      ?.hall_number
                  }}
                  зал</span
                >
                &nbsp;
                <span>{{
                  bestPrice$$()?.participation_bestprice?.[0]?.booths?.[0]
                    ?.booth_number
                }}</span>
              </p>
            </div>
          }
        </div>

        <div class="best-price-popup__discount-text">
          {{ bestPrice$$()?.discount_text }}
        </div>
      </div>
    </div>
  </div>

  <div class="popup__middle-container">
    <p
      class="best-price-popup__description"
      [innerHtml]="bestPrice$$()?.description"
    ></p>
  </div>

  @if (booths$$()?.length) {
    <ng-container fxFlexOrder.xs="1">
      <!-- <div
        class="best-price-popup__title best-price-popup__slider-title popup__middle-container"
      >
        Экспонент
      </div> -->
      <div class="best-price-popup__booths-slider">
        <pirexpo-exhibitors-slider
          title="Экспоненты"
          [brands]="$any(booths$$())"
        />
        <!-- <slider
          [config]="sliderConfig"
          [popupSlider]="true"
          [sliderTitle]="'Экспоненты'"
          [sliderName]="'best-price'"
        >
          <card-participation
            *ngFor="let booth of booths"
            class="swiper-slide"
            [boothDetail]="booth"
            [participationForPopup]="true"
          ></card-participation>
        </slider> -->
      </div>
    </ng-container>
  }
</mat-dialog-content>
