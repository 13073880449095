import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Store } from '@ngrx/store';
import { TicketActions, orderFeature } from '@pirexpo/entities/order';
import { ButtonComponent } from '@pirexpo/ui/buttons/button';
import { CircleButtonComponent } from '@pirexpo/ui/buttons/circle-button';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { SvgIconComponent } from 'angular-svg-icon';
import { filter } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    SvgIconComponent,
    CircleButtonComponent,
    TextButtonComponent,
    ButtonComponent,
  ],
  selector: 'pirexpo-restore-ticket-popup',
  standalone: true,
  styleUrls: ['./restore-ticket-popup.component.scss'],
  templateUrl: './restore-ticket-popup.component.html',
})
export class RestoreTicketPopupComponent {
  readonly emailControl = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, Validators.email],
  });

  readonly ticketRestore$$ = toSignal(
    this.store
      .select(orderFeature.selectTicketRestoreData)
      .pipe(filter((data) => !!data)),
  );

  readonly ticketRestoreLoading$$ = toSignal(
    this.store.select(orderFeature.selectRestoreLoading),
  );

  @Input() value!: unknown;

  constructor(
    private store: Store,
    private destroyRef: DestroyRef,
  ) {
    this.destroyRef.onDestroy(() =>
      this.store.dispatch(TicketActions.clearRestoreTicketData()),
    );
  }

  restoreTicket(): void {
    if (!this.emailControl.valid) return;

    this.store.dispatch(
      TicketActions.restoreTicket({
        ticketRestore: { email: this.emailControl.value },
      }),
    );
  }
}
