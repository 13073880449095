import { HttpClient } from '@angular/common/http';
import { StateKey, TransferState, makeStateKey } from '@angular/core';
import { SvgHttpLoader, SvgLoader } from 'angular-svg-icon';
import { Observable } from 'rxjs';

export class SvgBrowserLoader implements SvgLoader {
  constructor(
    private http: HttpClient,
    private transferState: TransferState,
  ) {}

  getSvg(url: string): Observable<string> {
    const key: StateKey<string> = makeStateKey<string>('transfer-svg:' + url);
    const data = this.transferState.get(key, null);

    if (data) {
      return new Observable((observer) => {
        observer.next(data);
        observer.complete();
      });
    } else {
      return new SvgHttpLoader(this.http).getSvg(url);
    }
  }
}
