import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { OrderActions, orderFeature } from '@pirexpo/entities/order';
import { ButtonComponent } from '@pirexpo/ui/buttons/button';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogModule, ButtonComponent],
  selector: 'pirexpo-order-renew-popup',
  standalone: true,
  styleUrls: ['./order-renew-popup.component.scss'],
  templateUrl: './order-renew-popup.component.html',
})
export class OrderRenewPopupComponent {
  readonly orderLoading$$ = toSignal(
    this.store.select(orderFeature.selectOrderLoading),
  );

  @Input() value!: string;

  constructor(private store: Store) {}

  renewOrder(): void {
    this.store.dispatch(OrderActions.renewOrder());
  }
}
