<mat-dialog-content class="order-renew-popup">
  <div class="order-renew-popup__container">
    <p class="order-renew-popup__text">
      Билеты по данному заказу уже забронированы, чтобы отредактировать заказ
      отмените бронирование.
    </p>

    <pirexpo-button
      class="order-renew-popup__renew-btn"
      size="middle"
      text="Отменить бронирование"
      [loading]="!!orderLoading$$()"
      (click)="renewOrder()"
    />
  </div>
</mat-dialog-content>
