import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Partner, PartnerDetail } from '@swagger/models';
import { PartnerService } from '@swagger/services/partner.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface PartnerPopupState {
  error?: unknown;
  loading?: boolean;
  partner?: PartnerDetail;
}

@Injectable()
export class PartnerPopupComponentStore extends ComponentStore<PartnerPopupState> {
  readonly error$$ = this.selectSignal((state) => state.error);

  readonly getPartnerDetailed = this.effect(
    (partnerId$: Observable<Partner['id']>) => {
      return partnerId$.pipe(
        tap(() => this.patchState({ loading: true })),
        switchMap((partnerId) =>
          this.partnerService.partnerRead(String(partnerId)).pipe(
            tapResponse({
              error: (error: unknown) =>
                this.patchState({ error, loading: false }),
              next: (partner) => this.patchState({ loading: false, partner }),
            }),
          ),
        ),
      );
    },
  );

  readonly loading$$ = this.selectSignal((state) => state.loading);

  readonly partner$$ = this.selectSignal((state) => state.partner);

  constructor(private partnerService: PartnerService) {
    super({});
  }
}
