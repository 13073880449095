import { DialogRef } from '@angular/cdk/dialog';
import { LowerCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Self,
  computed,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Params, RouterLink } from '@angular/router';
import {
  ROUTE_QUERY_PARAMS_TOKENS,
  ROUTE_TOKENS,
} from '@pirexpo/shared/app-config';
import { BoothPopup } from '@pirexpo/shared/model';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { BadgeComponent } from '@pirexpo/ui/common/badge';
import { SvgIconComponent } from 'angular-svg-icon';
import { skip, tap } from 'rxjs/operators';

import { ExhibitorPopupComponentStore } from './exhibitor-popup-component.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LowerCasePipe,
    RouterLink,
    MatDialogModule,
    FlexLayoutModule,
    BadgeComponent,
    SvgIconComponent,
    TextButtonComponent,
  ],
  providers: [ExhibitorPopupComponentStore],
  selector: 'pirexpo-exhibitor-popup',
  standalone: true,
  templateUrl: './exhibitor-popup.component.html',
})
export class ExhibitorPopupComponent implements OnInit {
  private readonly ROUTE_QUERY_PARAMS_TOKENS = ROUTE_QUERY_PARAMS_TOKENS;

  private readonly queryParams$$ = toSignal(
    this.route.queryParams.pipe(
      skip(1),
      tap(() => this.dialogRef.close()),
    ),
  );

  readonly ROUTE_TOKENS = ROUTE_TOKENS;

  readonly booth$$ = computed(() =>
    this.brand$$()?.booths?.find((b) => b.id === this.value.boothId),
  );

  readonly brand$$ = this.componentStore.brand$$;

  readonly classificator$$ = this.componentStore.classificator$$;

  @Input() value!: BoothPopup;

  constructor(
    @Self() private componentStore: ExhibitorPopupComponentStore,
    private route: ActivatedRoute,
    private dialogRef: DialogRef,
  ) {}

  ngOnInit(): void {
    if (this.value) this.componentStore.getBrandDetailed(this.value.brandId);
  }

  queryParams(id: number | undefined): Params {
    return {
      [this.ROUTE_QUERY_PARAMS_TOKENS.CATALOG]: id,
    };
  }
}
