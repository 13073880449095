<mat-dialog-content class="popup news-popup">
  <div class="popup__header">
    <img
      loading="lazy"
      class="popup__header-bg"
      alt="news-popup-bg"
      [src]="news$$()?.image"
    />

    <div
      class="popup__header-container popup__middle-container"
      fxLayout="column"
      fxLayoutAlign="flex-end flex-start"
      fxLayoutAlign.lt-md="space-between flex-start"
    >
      <pirexpo-text-button
        class="news-popup__back-button"
        mat-dialog-close
        text="Назад"
        buttonType="withIcon"
      >
        <svg-icon
          class="back-icon"
          src="/assets/icons/back.svg"
          [applyClass]="true"
        />
      </pirexpo-text-button>

      @if (!loading$$()) {
        @if (news$$()?.themes?.length) {
          <div
            class="relative"
            fxLayout="row"
            fxLayoutAlign="flex-start center"
          >
            @for (themeId of news$$()?.themes; track themeId) {
              <pirexpo-badge class="popup__badge" [type]="themeId + ''" />
            }
          </div>
        } @else {
          <pirexpo-badge text="Новость" type="transparent" />
        }
      }
    </div>
  </div>

  <div class="popup__container">
    <div class="popup__bottom-container">
      <div
        class="popup__text news-popup__text"
        [innerHtml]="news$$()?.description"
      ></div>

      @if (news$$()?.source_url) {
        <div class="news-popup__link">
          <span class="color-light-gray4">Источник:&nbsp;</span>

          <a
            class="color-red"
            rel="noopener"
            target="_blank"
            [href]="news$$()?.source_url"
            >{{ news$$()?.source_url }}</a
          >
        </div>
      }
    </div>
  </div>
</mat-dialog-content>
