/* eslint-disable no-console */
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as SentryBrowser from '@sentry/angular-ivy';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config.browser';
import { environment } from './environments';

SentryBrowser.init({
  dsn: 'https://7e5a2a00b452d4e3f11f55f98d06c97d@sentry.pir.ru/4',
  environment: environment.production ? 'production' : 'development',
  integrations: [
    new SentryBrowser.BrowserTracing({
      routingInstrumentation: SentryBrowser.routingInstrumentation,
    }),
    new SentryBrowser.Replay(),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  tracePropagationTargets: ['localhost'],
  tracesSampleRate: 1.0,
});

if (environment.production) enableProdMode();

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
