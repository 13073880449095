<div #menu class="menu" [class.menu_opened]="menuIsOpened$$()">
  <div
    class="menu__overlay"
    [class.menu__overlay_opened]="menuIsOpened$$()"
    (click)="close()"
  ></div>

  <div *transloco="let t; read: 'layout'" class="menu__container">
    <div class="menu__header">
      @if (isFiltersOpened$$()) {
        <p class="menu__filters-title">Фильтры</p>
      } @else {
        <div class="menu__lang-toggle" (click)="changeLang()">
          {{ (activeLang === 'ru' ? 'en' : 'ru') | uppercase }}
        </div>
      }

      <div class="menu__close" (click)="close()">
        {{ t('menu.close') | capitalize }}
      </div>

      <svg-icon
        class="menu__close-icon"
        src="/assets/icons/menu-close.svg"
        [applyClass]="true"
        (click)="close()"
      />
    </div>

    <ng-container *ngComponentOutlet="component$$()" />
  </div>
</div>
